import { Col } from "react-bootstrap";

const ProjectCards = ({ title, description, imgUrl, site }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt="project card" />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
          {site !== "" && (
            <>
              <br></br>
              <div className="mt-3">
                <a className="visit" href={site} target="_blank">
                  <span>Visiter</span>
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </Col>
  );
};

export default ProjectCards;
