import marvel from "../assets/img/marvel.png";
import ghiblist from "../assets/img/ghiblist.png";
import { Col, Container, Row, Nav, Tab } from "react-bootstrap";
import ProjectCards from "./ProjectCards";
import colorSharp2 from "../assets/img/color-sharp2.png";
import projectImg3 from "../assets/img/lmdlj.png";
import projectImg4 from "../assets/img/ghost.png";
import projectImg5 from "../assets/img/dash.png";
import projectImg6 from "../assets/img/chatty.png";

const Projects = () => {
  const projectsTabOne = [
    {
      title: "Admin Dashboard",
      description: "Dashboard intéractif connecté à une BDD Firebase",
      imgUrl: projectImg5,
      site: "https://gourcmathis.github.io/admin_dashboard/",
    },
    {
      title: "Chatty",
      description: "Chat connecté à une BDD Firebase",
      imgUrl: projectImg6,
      site: "https://gourcmathis.github.io/chatty/",
    },
    {
      title: "Marvel Quiz",
      description: "Quiz sur l'univers Marvel",
      imgUrl: marvel,
      site: "https://gourcmathis.github.io/marvel-quiz/",
    },
    {
      title: "Intégration Figma",
      description: "Intégration d'une maquette Figma",
      imgUrl: projectImg4,
      site: "https://gourcmathis.github.io/Integration_Figma/",
    },

    {
      title: "La maison de la Jungle",
      description: "Vente de plantes en tout genre",
      imgUrl: projectImg3,
      site: "https://gourcmathis.github.io/la_maison_de_la_jungle/",
    },
    {
      title: "Ghiblist",
      description: "Cherche et liste tes films Ghibli favoris",
      imgUrl: ghiblist,
      site: "https://gourcmathis.github.io/ghiblist/",
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col>
            <h2>Projets</h2>
            <p>
              Voici un petit apperçu de mes projets React terminés, n'hésitez
              pas à les tester en cliquant dessus ! Retrouvez le code source sur
              mon{" "}
              <b>
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="https://github.com/gourcmathis"
                  target="_blank"
                >
                  github
                </a>
              </b>
            </p>

            <Tab.Container id="projects-tabs" defaultActiveKey="first">
              <Nav
                variant="pills"
                className="nav-pills mb-5 justify-content-center align-items-center"
                id="pills-tab"
              >
                <Nav.Item>
                  <Nav.Link eventKey="first">Page 1</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Page 2</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">Page 3</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Row>
                    {projectsTabOne.map((project, index) => {
                      return <ProjectCards key={index} {...project} />;
                    })}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <p>En attente de projets futurs !</p>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <p>En attente de projets futurs !</p>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
      <img
        className="background-image-right"
        src={colorSharp2}
        alt="background"
      />
    </section>
  );
};

export default Projects;
