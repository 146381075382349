import { Container, Row, Col } from "react-bootstrap";
import MailchimpForm from "./MailchimpForm";
import logo from "../assets/img/logo.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.png";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6}>
            <img className="logoImg" src={logo} alt="logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://linkedin.com/in/mathisgourc" target="_blank">
                <img src={navIcon1} alt="linkedin-icon" />
              </a>
              <a href="https://github.com/gourcmathis" target="_blank">
                <img src={navIcon2} alt="github-icon" />
              </a>
            </div>
            <p>CopyRight © 2022. Tous droits réservés Mathis Gourc</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
