import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import headerImg from "../assets/img/header-img.svg";

const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const toRotate = ["Fullst", "Front End"];
  const [text, setText] = useState("");
  const [index, setIndex] = useState(1);
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <span className="tagline">Bienvenue dans mon Portfolio !</span>
            <div className="pres">
              <h1>
                {`Salut, c'est Mathis je suis développeur `}
                <span
                  className="txt-rotate"
                  dataperiod="1000"
                  data-rotate='["Fullst", "Front End"]'
                >
                  <span className="wrap">{text}</span>
                </span>
              </h1>
            </div>
            <div className="intro">
              <p>
                Passionné par le développement Front End, je vous accompagne
                dans vos projets web à travers la technologie React Js.
                J'éprouve un intérêt particulier pour les missions derrière
                lesquelles je trouve un sens à mon travail mais le meilleur
                moyen de savoir si une collaboration est possible c'est de voir
                cela ensemble !
              </p>
            </div>

            <a className="banner-contact" href="#connect">
              Contact <ArrowRightCircle size={25} />
            </a>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <img src={headerImg} alt="Headder Img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
